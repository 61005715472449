import React, { useState, useEffect } from "react";
import axios from "axios";
import { Layout, Col, Row, Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { navigate } from "../utils";
import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

export default function ConfirmResetPassword() {
  const [resetHash, setResetHash] = useState(null);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const hastLastPart = window.location.hash.split("#").pop();
    setResetHash(hastLastPart);
    if (!hastLastPart) {
      navigate("/");
      return null;
    }
  }, []);

  function handleSubmit({ password }) {
    setIsLoading(true);
    axios
      .post(process.env.GATSBY_API_URL + "/confirm-reset-password", {
        resetHash,
        password,
      })
      .then(() => {
        message.info("Votre mot de passe a été réinitialisé");
        navigate("/login-mon-espace-rider");
      })
      .catch(() => {
        message.error("Erreur lors de la demande de réinitialisation");
        navigate("/login-mon-espace-rider");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Mot de passe de mon Espace Rider</h2>
            <p>
              Redéfinissez votre mot de passe afin de pouvoir accèder à votre
              espace Rider
            </p>
            <Row>
              <Col
                lg={{ span: 10, offset: 7 }}
                xs={24}
                style={{ textAlign: "center" }}
              >
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  className="login-form"
                >
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Merci de saisir votre mot de passe",
                      },
                      () => ({
                        validator(_, value) {
                          const isNonWhiteSpace = /^\S*$/;
                          if (!isNonWhiteSpace.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Le mot de passe ne peut pas contenir d'espace"
                              )
                            );
                          }
                          const isContainsNumber = /^(?=.*[0-9]).*$/;
                          if (!isContainsNumber.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Le mot de passe doit contenir un chiffre"
                              )
                            );
                          }
                          const isValidLength = /^.{8,16}$/;
                          if (!isValidLength.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Le mot de passe doit être entre 8 et 16 caractères"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="Mot de passe"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password2"
                    rules={[
                      {
                        required: true,
                        message: "Merci de confirmer votre mot de passe",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Les 2 mots de passe ne correspondent pas"
                            )
                          );
                        },
                      }),
                    ]}
                    dependencies={["password"]}
                  >
                    <Input
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="Confirmation mot de passe"
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ marginTop: "2em" }}
                    loading={isLoading}
                  >
                    Réinitialiser
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
